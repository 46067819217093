// import { Query } from '@apollo/client/react/components'
import { useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { useMutation } from '@apollo/react-hooks'
import { Skeleton, PageHeader, Select, Modal, Form, notification } from 'antd'
import {
  QUERY_TRANSACTION_PLANS_BY_TYPE,
  UPDATE_MERCHANT_TRANSACTION_PLAN
} from '../TransactionPlan/Queries'

const TransactionPlanSelect = ({ client, merchant }) => {
  const { loading, data } = useQuery(QUERY_TRANSACTION_PLANS_BY_TYPE, {
    client: client,
    fetchPolicy: 'no-cache',
    variables: {
      stripeAccountType: merchant?.stripe_account_type,
      currentPlanId: merchant?.transaction_plan_id
    }
  })

  const [updateMerchantPlan, { loading: updateLoading }] = useMutation(
    UPDATE_MERCHANT_TRANSACTION_PLAN,
    { client }
  )

  useEffect(() => {
    if (merchant && data && data.transaction_plans) {
      const plan = getInitialPlan(data.transaction_plans, merchant)
      setActivePlan(plan)
      setInitialPlan(plan)
    }
  }, [merchant, data])

  const getInitialPlan = (plans, merchant) => {
    const plan = plans?.find((p) => p.id === merchant.transaction_plan_id)
    const defaultPlan = plans?.find((p) => p.defaulted_at)
    return plan || defaultPlan
  }

  const [modalVisible, setModalVisible] = useState(false)
  const [activePlan, setActivePlan] = useState(null)
  const [initialPlan, setInitialPlan] = useState(
    getInitialPlan(data && data.transaction_plans, merchant)
  )

  if (loading && !activePlan) {
    return <Skeleton active />
  }
  if (data && data.transaction_plans) {
    return (
      <>
        <Modal
          title='Merchant Transaction Plan'
          visible={modalVisible}
          onCancel={() => {
            setModalVisible(false)
            setActivePlan(initialPlan)
          }}
          onOk={() => {
            const vars = {
              variables: { merchantId: merchant.id, planId: activePlan.id }
            }
            updateMerchantPlan(vars)
              .then(() => {
                notification['success']({
                  message: 'Successfully updated merchant transaction plan'
                })
              })
              .catch((error) => {
                console.error(error)
                notification['error']({
                  message: 'Failed to update merchant transaction plan'
                })
              })
              .finally(() => {
                setModalVisible(false)
                setInitialPlan(activePlan)
              })
          }}
          confirmLoading={updateLoading}
        >
          {`You are about to set this Merchant's Transaction Plan to: ${activePlan?.name}`}
        </Modal>
        <PageHeader title='Transaction Plan' />
        <Form labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}>
          <Form.Item
            label='Transaction Plan'
            extra='Please note that selecting a new plan from these options will change the merchant transaction plan.'
          >
            <Select
              style={{ width: 250 }}
              value={activePlan?.id}
              onChange={(id) => {
                setActivePlan(data?.transaction_plans?.find((p) => p.id === id))
                setModalVisible(true)
              }}
            >
              {data?.transaction_plans?.map((plan) => {
                return (
                  <Select.Option key={plan.id} value={plan?.id}>
                    {plan?.name}
                    {plan?.defaulted_at && ' (Default)'}
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>
        </Form>
      </>
    )
  }
  return null
}

export default TransactionPlanSelect
