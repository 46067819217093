import { useState } from 'react'
import { useMutation } from '@apollo/react-hooks'

import { Form, Select, notification, Modal, PageHeader } from 'antd'
import { UPDATE_MERCHANT_SUBSCRIPTION_PLAN } from './MerchantQueries'

const { Option } = Select

interface SubscriptionPlanProps {
  plans: any
  initialPlan: any
  initialPlanOptions: any
  merchant: any
  client: any
}

const SubscriptionPlan = ({
  plans,
  initialPlan,
  initialPlanOptions,
  merchant,
  client
}: SubscriptionPlanProps) => {
  const [modalShown, setModalShown] = useState(false)
  const [selectedPlan, setSelectedPlan] = useState(initialPlan)
  const [planOptions, setPlanOptions] = useState(initialPlanOptions)
  const [isActivating, setIsActivating] = useState(false)

  const [updateSubscriptionPlan] = useMutation(
    UPDATE_MERCHANT_SUBSCRIPTION_PLAN,
    { client: client }
  )

  return (
    <>
      <PageHeader title='Subscription Plan' />
      <Modal
        title='Merchant Subscription Plan'
        visible={modalShown}
        onOk={() => {
          setIsActivating(true)

          updateSubscriptionPlan({
            variables: {
              merchantId: merchant.id,
              plan: selectedPlan.code
            }
          })
            .then(() => {
              notification['success']({
                message: 'Successfully updated merchant subscription plan'
              })
            })
            .catch(() => {
              notification['error']({
                message: 'Failed to update merchant subscription plan'
              })
            })
            .finally(() => {
              setModalShown(false)
              setIsActivating(false)
              setPlanOptions(plans.filter((x) => x.code !== selectedPlan.code))
            })
        }}
        onCancel={() => {
          setModalShown(false)
          setSelectedPlan(initialPlan)
        }}
        confirmLoading={isActivating}
      >
        {`You are about to set this Merchant's Subscription Plan to: ${selectedPlan.name}`}
      </Modal>
      <Form labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}>
        <Form.Item
          label='Plan'
          extra='Please note that selecting a new plan from these options will change the merchant subscription plan.'
        >
          <Select
            value={selectedPlan.name}
            style={{ width: 250 }}
            onChange={(planCode) => {
              setSelectedPlan(plans.find((p) => p.code === planCode))
              setModalShown(true)
            }}
          >
            {planOptions.map((plan) => {
              return (
                <Option key={plan.code} value={plan.code}>
                  {plan.name}
                </Option>
              )
            })}
          </Select>
        </Form.Item>
      </Form>
    </>
  )
}

export default SubscriptionPlan
