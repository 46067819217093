import { gql } from '@apollo/client'
import moment from 'moment-timezone'

const OVERRIDE_DELIVERY = gql`
  mutation overrideDelivery(
    $orderId: uuid!
    $delivery: jsonb!
    $metadata: jsonb!
    $eventLog: event_logs_insert_input!
  ) {
    update_orders_by_pk(
      pk_columns: { id: $orderId }
      _append: { metadata: $metadata, delivery: $delivery }
    ) {
      delivery
      metadata
    }
    insert_event_logs_one(object: $eventLog) {
      id
    }
  }
`

const ORDERS_LIST_FRAGMENT = gql`
  {
    merchant {
      name
      slug
    }
    transaction_id
    store {
      name
      address {
        contact_num
      }
      email
      comtrex_settings: settings(path: "comtrex")
      deliverect_link_id: settings(path: "deliverect_link_id")
    }
    delivery
    total
    fulfillment_type
    fulfillment_date
    fulfillment_time_range
    status
    inserted_at
    customer_details
    create_device_category
    create_platform
    metadata
  }
`

export const buildQuery = (whereClause: string = '{}', limit: number = 100) => {
  return gql`
  subscription customOrdersList {
    orders(where: ${whereClause}, order_by: { inserted_at: desc }, limit: ${limit}) ${ORDERS_LIST_FRAGMENT}
  }`
}

export const buildOpsQuery = (limit: number = 50) => {
  const startOfDay = moment().startOf('day').format('YYYY-MM-DD HH:mm:ss')
  const endOfDay = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')

  return gql`
    query getOpsOrders($startOfDay: timestamp = "${startOfDay}", $endOfDay: timestamp = "${endOfDay}", $limit: Int = ${limit}) {
      orders(order_by: {inserted_at: desc}, limit: $limit, where: {_or: [ {status: {_eq: "pending"}, inserted_at: { _gte: $startOfDay }, fulfillment_type: {_eq: "delivery"}, _or: [{fulfillment_date: {_gte: $startOfDay, _lte: $endOfDay}}, {fulfillment_date: {_is_null: true}}]}, {merchant: {partners_merchants: {partner: {name: {_in: ["deliverect", "comtrex"]}}}}}]}) 
      ${ORDERS_LIST_FRAGMENT}
  }
  `
}

const SUBSCRIPTION_LATEST_ORDERS = gql`
  subscription ordersList {
    orders(order_by: { inserted_at: desc }, limit: 240) ${ORDERS_LIST_FRAGMENT}
  }
`

const SUBSCRIPTION_PENDING_ORDERS = gql`
  subscription pendingOrdersList {
    orders: view_pending_orders_today {
      minutes_delayed
      order {
        merchant {
          name
        }
        transaction_id
        store {
          id
          name
          email
          address {
            contact_num
          }
          app_logs(order_by: { created_at: desc }, limit: 1) {
            device_model
            device_os
            app_version
            created_at
          }
        }
        total
        fulfillment_type
        fulfillment_date
        fulfillment_time_range
        status
        inserted_at
        customer_details
      }
    }
  }
`

const SUBSCRIBE_ORDER = gql`
  subscription subscribeOrder($transactionId: String!) {
    orders(where: { transaction_id: { _eq: $transactionId } }) {
      id
      merchant {
        name
        slug
      }
      transaction_id
      store {
        name
        address {
          geom
        }
      }
      total
      subtotal
      delivery
      delivery_charge
      delivery_eta
      fulfillment_date
      fulfillment_type
      fulfillment_time_range
      create_device_category
      create_platform
      inserted_at
      discounts_cache
      customer_details
      payment
      additional_notes
      status
      gift_wrap_message
      gift_wrap_price
      order_items {
        product_variant {
          image
          name
        }
        quantity
        vat
        amount
        applied_modifiers {
          amount
          vat
          price
          quantity
          modifier {
            id
            name
          }
          modifier_group {
            name
          }
        }
      }
      additional_couriers {
        data
        address
        job_reference
        rebooking_fee
        dropoff_notes
        fulfillment_date
      }
      event_logs {
        payload
        inserted_at
      }
      metadata
    }
  }
`

const QUERY_ORDER_LOCATIONS = gql`
  query orderCoords {
    view_order_coords(limit: 1000, order_by: { inserted_at: desc }) {
      coords
    }
  }
`

const MUTATION_RETRY_POS_ORDER = gql`
  mutation RetryPOSOrder($transactionId: ID!) {
    retryPosOrder(transactionId: $transactionId) {
      id
    }
  }
`

const QUERY_ORDER_TRACKING_TOKEN = gql`
  query GetOrderTrackingToken($transactionId: ID!) {
    getOrderTrackingToken(transactionId: $transactionId)
  }
`

export {
  SUBSCRIPTION_LATEST_ORDERS,
  SUBSCRIPTION_PENDING_ORDERS,
  SUBSCRIBE_ORDER,
  QUERY_ORDER_LOCATIONS,
  QUERY_ORDER_TRACKING_TOKEN,
  MUTATION_RETRY_POS_ORDER,
  OVERRIDE_DELIVERY
}
