import { Layout } from 'antd'
import { ApolloConsumer } from '@apollo/client'

import { default as LoginForm } from './LoginForm'

const { Content } = Layout

const Login = (props) => {
  return (
    <ApolloConsumer>
      {(client) => {
        return (
          <Layout>
            <Content>
              <LoginForm {...props} />
            </Content>
          </Layout>
        )
      }}
    </ApolloConsumer>
  )
}

export default Login
