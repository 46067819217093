import { gql } from '@apollo/client'

export const QUERY_MONTHLY_KPI = gql`
  query ($from: date!, $to: date!) {
    totals: view_monthly_kpi_totals(
      where: { _and: [{ month: { _gte: $from } }, { month: { _lte: $to } }] }
    ) {
      month
      slerp_fee
      courier_fee
      application_fee
      gmv
      gtv
      self_fulfilled
      pickup
      table_order
      last_mile
      asap
      ssd
      pre
      stores
      count
      merchants
      platform_count_web_mobile
      platform_count_web_desktop
      platform_count_ios_app
      platform_count_android_app
      platform_count_unknown
      platform_gmv_web_mobile
      platform_gmv_web_desktop
      platform_gmv_ios_app
      platform_gmv_android_app
      platform_gmv_unknown
      platform_aov_web_mobile
      platform_aov_web_desktop
      platform_aov_ios_app
      platform_aov_android_app
      platform_aov_unknown
      platform_refunds_web_mobile
      platform_refunds_web_desktop
      platform_refunds_ios_app
      platform_refunds_android_app
      platform_refunds_unknown
    }
    by_sector: view_monthly_kpi_by_sector(
      where: { _and: [{ month: { _gte: $from } }, { month: { _lte: $to } }] }
    ) {
      month
      sector
      gmv
      count
    }
  }
`

export const QUERY_COMBINED_MONTHLY_KPI = gql`
  query ($from: date!, $to: date!) {
    totals: view_monthly_kpi_totals(
      where: { _and: [{ month: { _gte: $from } }, { month: { _lte: $to } }] }
    ) {
      month
      slerp_fee
      courier_fee
      gmv
      gtv
      self_fulfilled
      pickup
      table_order
      last_mile
      asap
      ssd
      pre
      stores
      count
      merchants
    }
    by_sector: view_monthly_kpi_by_sector(
      where: { _and: [{ month: { _gte: $from } }, { month: { _lte: $to } }] }
    ) {
      month
      sector
      gmv
      count
    }
    monthly_stampapp_bins {
      data
      id
      month
    }
  }
`

export const QUERY_MERCHANT_MONTHLY_KPI = gql`
  query ($merchantId: uuid!, $from: date!, $to: date!) {
    totals: monthly_bins(
      where: {
        _and: [
          { merchant_id: { _eq: $merchantId } }
          { month: { _gte: $from } }
          { month: { _lte: $to } }
        ]
      }
      order_by: { month: asc }
    ) {
      month
      components
    }
  }
`

export const QUERY_AVAILABLE_KPI_MONTHS = gql`
  query {
    months: view_monthly_kpi_totals {
      month
    }
  }
`

export const QUERY_STAMP_APP_BINS = gql`
  query StampAppBins {
    monthly_stampapp_bins {
      data
      id
      month
    }
  }
`
