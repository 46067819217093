import { gql } from '@apollo/client'

export const QUERY_MERCHANT_STATS_YEARS = gql`
  query {
    years: view_monthly_merchants(distinct_on: year) {
      year
    }
  }
`

export const QUERY_MERCHANT_STORE_COUNTS = gql`
  query ($year: float8) {
    merchant_counts: view_monthly_merchants(where: { year: { _eq: $year } }) {
      year
      month
      count
    }
    store_counts: view_monthly_stores(where: { year: { _eq: $year } }) {
      year
      month
      count
    }
  }
`

export const QUERY_ORDER_STATS = gql`
  query ($year: float8) {
    order_stats: view_monthly_orders(where: { year: { _eq: $year } }) {
      year
      month
      count
      subtotal
      total
    }
  }
`

export const QUERY_ORDER_DELIVERY_STATS = gql`
  query ($year: float8) {
    order_stats: view_monthly_delivery_orders(where: { year: { _eq: $year } }) {
      year
      month
      count
      subtotal
      total
    }
  }
`

export const QUERY_ORDER_PICKUP_STATS = gql`
  query ($year: float8) {
    order_stats: view_monthly_pickup_orders(where: { year: { _eq: $year } }) {
      year
      month
      count
      subtotal
      total
    }
  }
`

export const QUERY_DELIVERY_PICKUP_STATS = gql`
  query ($year: float8) {
    delivery_stats: view_monthly_delivery_orders(
      where: { year: { _eq: $year } }
    ) {
      year
      month
      count
      subtotal
      total
    }
    pickup_stats: view_monthly_pickup_orders(where: { year: { _eq: $year } }) {
      year
      month
      count
      subtotal
      total
    }
  }
`
