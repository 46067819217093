import { Row, Col, Skeleton, PageHeader } from 'antd'
import { useQuery } from '@apollo/client'
import { QUERY_MERCHANT_STORE_COUNTS } from './StatsQueries'
import { LineChart, AreaChart } from 'react-chartkick'
import { monthlySeries, cumulativeSeries } from './utils'

const MerchantStats = ({ year }) => {
  const { data, loading } = useQuery(QUERY_MERCHANT_STORE_COUNTS, {
    variables: { year }
  })

  if (loading) {
    return <Skeleton paragraph={{ rows: 3 }} active loading />
  }

  if (data?.merchant_counts && data?.store_counts) {
    const merchants = monthlySeries(data.merchant_counts, year)
    const merchantsCumulative = cumulativeSeries(merchants)

    const stores = monthlySeries(data.store_counts, year)
    const storesCumulative = cumulativeSeries(stores)

    const countSeries = [
      {
        name: 'Partners',
        data: merchants
      },
      {
        name: 'Stores',
        data: stores
      }
    ]

    const countCumulativeSeries = [
      {
        name: 'Partners',
        data: merchantsCumulative
      },
      {
        name: 'Stores',
        data: storesCumulative
      }
    ]

    return (
      <Row gutter={50}>
        <Col xl={24} md={24}>
          <PageHeader title='Count' />
          <LineChart
            data={countSeries}
            curve={false}
            colors={['#1890ff', '#18b040']}
          />
          <br />
          <PageHeader title='Cumulative' />
          <AreaChart
            data={countCumulativeSeries}
            colors={['#1890ff', '#18b040']}
          />
        </Col>
      </Row>
    )
  }

  return null
}

export default MerchantStats
