import { PageHeader, Empty, Result, Skeleton } from 'antd'
import OrderDetails from './OrderDetails'
import { QUERY_ORDER_TRACKING_TOKEN, SUBSCRIBE_ORDER } from './OrderQueries'
import { useQuery, useSubscription } from '@apollo/client'

interface OrderInfoProps {
  order: any
  client: any
}

const OrderInfo = (props: OrderInfoProps) => {
  const { order, client } = props

  // Gets order tracking token so ops can view order tracking page
  const {
    data: { getOrderTrackingToken } = {},
    loading: isRetrievingTrackingToken
  } = useQuery(QUERY_ORDER_TRACKING_TOKEN, {
    skip: !order,
    variables: {
      transactionId: order?.transaction_id
    }
  })

  const {
    data: subscriptionData,
    error: subscriptionError,
    loading: subscriptionLoading
  } = useSubscription(SUBSCRIBE_ORDER, {
    skip: !order,
    variables: {
      transactionId: order?.transaction_id
    }
  })

  if (order === null || isRetrievingTrackingToken) {
    return <Empty />
  }

  return (
    <>
      <PageHeader title={`Order #${order.transaction_id}`} />
      {subscriptionLoading && <Skeleton active />}

      {(subscriptionError ||
        (subscriptionData && subscriptionData.message === null)) && (
        <Result
          status='500'
          title='500'
          subTitle={subscriptionError?.message}
        />
      )}

      {subscriptionData?.orders?.[0] ? (
        <OrderDetails
          order={subscriptionData.orders[0]}
          client={client}
          orderTrackingToken={getOrderTrackingToken}
        />
      ) : (
        <Result
          status='404'
          title='Invalid order'
          subTitle="This order doesn't exist"
        />
      )}
    </>
  )
}

export default OrderInfo
