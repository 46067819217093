import { useState, useRef } from 'react'
import { Button, Card, Col, Row, Select } from 'antd'
import {
  DeleteOutlined,
  EditOutlined,
  SaveOutlined,
  UploadOutlined
} from '@ant-design/icons'
import { v4 as uuidv4 } from 'uuid'
import PricingTable from './PricingTable'

interface Store {
  id: string
  name: string
}

interface RateCardDetailProps {
  id: string
  name: string
  priceMatrix: {
    car: { [key: number]: string }
    bike: { [key: number]: string }
    motorbike: { [key: number]: string }
  }
  priceMatrixStores?: Array<string>
  updateRateCard: (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => void
  archiveRateCard: (id: string) => void
  assignRateCardToStore: (id: string, priceMatrixId: string) => void
  removeRateCardFromStore: (storeId: string, priceMatrixId: string) => void
  renameRateCard: (priceMatrixId: string, name: string) => void
  merchantStores: Store[]
  availableStores: Array<string>
  isGlobal: boolean
  loading: boolean
}

const { Option } = Select

const RateCardDetail = ({
  id,
  name,
  priceMatrix,
  priceMatrixStores,
  updateRateCard,
  merchantStores,
  availableStores,
  archiveRateCard,
  assignRateCardToStore,
  removeRateCardFromStore,
  renameRateCard,
  isGlobal,
  loading
}: RateCardDetailProps) => {
  const [tableVisible, setTableVisible] = useState<boolean>(false)
  const [selectedStores, setSelectedStores] = useState<string[]>(
    priceMatrixStores || []
  )

  const [editName, setEditName] = useState<boolean>(false)

  const uploadButtonRef = useRef<HTMLInputElement>(null)
  const editNameInputRef = useRef<HTMLInputElement>(null)

  const saveRateCardNameHandler = (id: string, newName: string) => {
    renameRateCard(id, newName)
    setEditName(false)
  }

  const RateCard = () => {
    return (
      <Card className='card' bodyStyle={{ padding: '16px 8px' }}>
        <Row>
          <Col span={24}>
            <Row className='name'>
              <Col span={22}>
                {editName && (
                  <input ref={editNameInputRef} placeholder={name} />
                )}
                {!editName && name}
              </Col>
              <Col span={2}>
                {editName && (
                  <SaveOutlined
                    onClick={() =>
                      saveRateCardNameHandler(
                        id,
                        editNameInputRef.current.value
                      )
                    }
                  />
                )}
                {!editName && (
                  <EditOutlined onClick={() => setEditName(true)} />
                )}
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <Button
                  className='button -view'
                  size='small'
                  type='link'
                  onClick={() => setTableVisible(!tableVisible)}
                >
                  {tableVisible ? 'hide' : 'view'} details
                </Button>
              </Col>
              <Col span={8} offset={8}>
                <Row gutter={[4, 0]} justify='end'>
                  <Col>
                    <Button
                      loading={loading}
                      className='button -delete'
                      icon={<DeleteOutlined />}
                      size='small'
                      onClick={() => archiveRateCard(id)}
                    />
                  </Col>
                  <Col>
                    <Button
                      loading={loading}
                      icon={<UploadOutlined />}
                      size='small'
                      type='primary'
                      onClick={() => uploadButtonRef.current.click()}
                    />
                    <input
                      ref={uploadButtonRef}
                      hidden
                      type='file'
                      accept='.csv'
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        updateRateCard(e, id)
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    )
  }

  const selectStore = (storeId: string) => {
    assignRateCardToStore(storeId, id)
    setSelectedStores([...selectedStores, storeId])
  }

  const deselectStore = (storeId: string) => {
    const newSelectedStores = selectedStores.filter(
      (selectedStoreId: string) => selectedStoreId !== storeId
    )
    // store level rate card should still have at least 1 store attached to it
    if (newSelectedStores.length === 0) return
    removeRateCardFromStore(storeId, id)

    setSelectedStores(newSelectedStores)
  }

  return (
    <>
      <Row gutter={[16, 0]}>
        <Col>
          <RateCard />
        </Col>
        <Col span={8}>
          <Row gutter={[4, 0]}></Row>
          <br />
          {!isGlobal && (
            <>
              <Row justify='start'>
                <Col>Assign to store:</Col>
              </Row>
              <Row>
                <Col span={15}>
                  <Select
                    value={selectedStores}
                    mode='multiple'
                    style={{ width: '100%' }}
                    placeholder='select store/s'
                    onSelect={selectStore}
                    onDeselect={deselectStore}
                  >
                    {merchantStores.map((store: Store) => {
                      const disabled =
                        !availableStores.includes(store.id) &&
                        !priceMatrixStores.includes(store.id)
                      return (
                        <Option
                          key={uuidv4()}
                          disabled={disabled}
                          value={store.id}
                          label={store.name}
                        >
                          {store.name}
                        </Option>
                      )
                    })}
                  </Select>
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
      {tableVisible && (
        <>
          <Row>
            <Col span={12}>{name}</Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <Button
                className='button -view'
                size='small'
                type='link'
                onClick={() => setTableVisible(false)}
              >
                hide details
              </Button>
            </Col>
          </Row>
        </>
      )}
      {tableVisible && <PricingTable priceMatrix={priceMatrix} />}
    </>
  )
}

export default RateCardDetail
