import { useMutation } from '@apollo/react-hooks'
import {
  Descriptions,
  Select,
  notification,
  Form,
  Input,
  Button,
  Radio
} from 'antd'
import { OVERRIDE_DELIVERY } from './OrderQueries'
import { formatISO } from 'date-fns'
import { v4 as uuidv4 } from 'uuid'

const { Option } = Select
const DELIVERY_STATUSES = [
  'searching',
  'scheduled',
  'picking',
  'delivering',
  'delivered',
  'cancelled'
]

interface DeliveryControlProps {
  order: any
  client: any
}

const DeliveryControl = ({ order, client }: DeliveryControlProps) => {
  const [override] = useMutation(OVERRIDE_DELIVERY, { client })
  const [form] = Form.useForm()

  const doOverride = (values) => {
    const { jobId, deliveryStatus, transportType } = values

    const eventLog = {
      payload: {
        type: 'delivery_status_update',
        data: {
          from: order.delivery.delivery_status,
          to: deliveryStatus
        },
        id: uuidv4()
      },
      id: uuidv4(),
      inserted_at: new Date(),
      updated_at: new Date(),
      order_id: order.id
    }

    const latentMetadata = order.metadata.isOverriden
      ? {}
      : { oldJobId: order.delivery.job_id }

    const params = {
      variables: {
        orderId: order.id,
        delivery: {
          driver: {
            first_name: 'SLERP Operations',
            transport_type: transportType
          },
          delivery_status: deliveryStatus,
          updated_at: formatISO(new Date()),
          job_id: jobId,
          proxy: 'slerp_managed',
          proxy_job_id: jobId,
          default_transport_type: transportType
        },
        metadata: { isOverriden: true, ...latentMetadata },
        eventLog: eventLog
      }
    }

    override(params)
      .then(() => {
        notification['success']({ message: 'Override successful' })
      })
      .catch(() => {
        notification['error']({ message: 'Failed to override' })
      })
  }

  const itemLayout = { labelCol: { span: 5 }, wrapperCol: { span: 7 } }

  const vehicleOptions = [
    { label: 'Bike', value: 'bike' },
    { label: 'Motorbike', value: 'motorbike' },
    { label: 'Car', value: 'car' }
  ]

  return (
    <>
      <Descriptions
        title='Delivery control'
        layout='horizontal'
        column={6}
        size='small'
      />
      <Form form={form} layout='horizontal' onFinish={doOverride}>
        <Form.Item
          label='Delivery status override'
          {...itemLayout}
          name='deliveryStatus'
          initialValue={
            (order.delivery && order.delivery.delivery_status) || ''
          }
        >
          <Select style={{ textTransform: 'capitalize' }}>
            <Option disabled value=''>
              {' '}
              Override Status{' '}
            </Option>
            {DELIVERY_STATUSES.map((status) => {
              return (
                <Option
                  value={status}
                  key={status}
                  style={{ textTransform: 'capitalize' }}
                >
                  {' '}
                  {status.split('_').join(' ')}{' '}
                </Option>
              )
            })}
          </Select>
        </Form.Item>
        <Form.Item
          label='Job ID Override'
          {...itemLayout}
          name='jobId'
          initialValue={(order.delivery && order.delivery.job_id) || ''}
        >
          <Input placeholder='Job ID' />
        </Form.Item>
        <Form.Item
          label='Transport type'
          {...itemLayout}
          name='transportType'
          initialValue={
            (order.delivery.driver && order.delivery.driver.transport_type) ||
            order.delivery.default_transport_type
          }
        >
          <Radio.Group options={vehicleOptions} />
        </Form.Item>
        <Form.Item wrapperCol={{ span: 14, offset: 10 }}>
          <Button type='primary' htmlType='submit'>
            {' '}
            Submit{' '}
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}

export default DeliveryControl
