import { useQuery } from '@apollo/client'
import { QUERY_LATEST_MERCHANT_ORDERS } from './OrderQueries'
import OrdersList from './OrdersList'
import { Skeleton } from 'antd'

interface OrdersContainerProps {
  client: any
  slug: string
}

const OrdersContainer = ({ client, slug }: OrdersContainerProps) => {
  const { data, loading } = useQuery(QUERY_LATEST_MERCHANT_ORDERS, {
    variables: { slug }
  })

  return (
    <>
      <div className='orders-filter'></div>
      <div className='orders'>
        {loading && <Skeleton paragraph={{ rows: 10 }} active loading />}

        {data?.orders && (
          <OrdersList
            orders={data.orders}
            merchant={data.merchants[0]}
            client={client}
          />
        )}
      </div>
    </>
  )
}

export default OrdersContainer
