import { Table, PageHeader, Tag } from 'antd'
import { Link } from 'react-router-dom'

const MerchantsList = ({ merchants, client }) => {
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => {
        return <Link to={`/merchants/${record.slug}`}>{text}</Link>
      }
    },
    {
      title: 'Stores',
      key: 'stores_count',
      render: (text, record) => {
        return (
          <>
            <Tag color='green'>{record.open} open</Tag>
            <span></span>
            <Tag color='red'> {record.closed} closed</Tag>
          </>
        )
      }
    },
    {
      title: 'Pending Orders',
      dataIndex: 'pending_orders',
      key: 'pending_orders',
      render: (text) => text || 0
    },
    {
      title: 'Orders',
      dataIndex: 'total_orders',
      key: 'total_orders',
      render: (text) => {
        return <span>£{text || 0}</span>
      }
    }
  ]

  return (
    <>
      <PageHeader title='Top 10 Partners' />
      <Table dataSource={merchants} columns={columns} pagination={false} />
    </>
  )
}

export default MerchantsList
