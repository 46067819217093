import { useCallback, useEffect, useState } from 'react'
import { Form, Input, Button, message, PageHeader, Table } from 'antd'
import base64 from 'base-64'
import JSONView from '../Utils/JSONView'

const requestUrl = `https://api.twilio.com/2010-04-01/Accounts/${process.env.REACT_APP_TWILIO_SID}/Messages.json`
const username = base64.encode(
  `${process.env.REACT_APP_TWILIO_SID}:${process.env.REACT_APP_TWILIO_TOKEN}`
)

const SmsUtil = (props) => {
  const from = process.env.REACT_APP_TWILIO_NUMBER
  const [to, setTo] = useState(props.to)
  const [body, setBody] = useState(props.body)
  const [response, setResponse] = useState({})
  const [loadRecent, setLoadRecent] = useState(props.loadRecent || false)

  const sendSms = (e) => {
    e.preventDefault()
    e.stopPropagation()

    message.loading('Sending sms... Please wait.')

    const requestBody = new URLSearchParams({ To: to, From: from, Body: body })

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        Authorization: `Basic ${username}`
      },
      body: requestBody.toString()
    }

    fetch(requestUrl, options)
      .then(async (result) => {
        message.destroy()
        message.success('Message delivered!', 1)
        setResponse(await result.json())
        setLoadRecent(true)
      })
      .catch((err) => {
        message.destroy()
        message.error(
          `Unable to send the message. Please try again or contact tech support ${err.message}`,
          1
        )
        setResponse(err)
      })
  }

  return (
    <>
      <Form>
        <Form.Item label='To'>
          <Input
            name='to'
            defaultValue={to}
            onChange={(e) => {
              setTo(e.target.value)
            }}
          />
        </Form.Item>

        <Form.Item label='Message'>
          <Input.TextArea
            name='body'
            rows={4}
            defaultValue={body}
            onChange={(e) => {
              setBody(e.target.value)
            }}
          />
        </Form.Item>

        <Button type='primary' onClick={sendSms}>
          Send
        </Button>
      </Form>

      {response && (
        <>
          <PageHeader title='API Response' subTitle='Please ' />
          <JSONView src={response} />
        </>
      )}

      {loadRecent && <RecentMessages />}
    </>
  )
}

export const RecentMessages = () => {
  const [recentMessages, setRecentMessages] = useState()

  const loadMessages = useCallback(() => {
    fetch(`${requestUrl}?Page=0&PageSize=100`, {
      method: 'GET',
      headers: {
        Authorization: `Basic ${username}`
      }
    })
      .then(async (result) => {
        const messages = (await result.json()).messages
        setRecentMessages(messages)
      })
      .catch((err) => {
        message.error(`Error loading messages: ${err.message}`)
      })
  }, [])

  useEffect(() => {
    loadMessages()
  }, [loadMessages])

  const columns = [
    {
      title: 'From',
      key: 'from',
      dataIndex: 'from'
    },
    {
      title: 'To',
      key: 'to',
      dataIndex: 'to'
    },
    {
      title: 'Body',
      key: 'body',
      dataIndex: 'body'
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status'
    },
    {
      title: 'Date Sent',
      key: 'date_sent',
      dataIndex: 'date_sent'
    }
  ]

  return (
    <>
      <PageHeader title='SMS History' />
      <Table dataSource={recentMessages} columns={columns} />
    </>
  )
}

export default SmsUtil
