import { useState } from 'react'
import {
  Divider,
  Typography,
  Tabs,
  Skeleton,
  Menu,
  Dropdown,
  Button
} from 'antd'
import { CaretDownOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/client'
import { QUERY_MERCHANT_STATS_YEARS } from './StatsQueries'
import MerchantStats from './MerchantStats'
import DeliveryPickupStats from './DeliveryPickupStats'

const { TabPane } = Tabs

const StatsContainer = () => {
  const [year, setYear] = useState(2020)
  const { data, loading } = useQuery(QUERY_MERCHANT_STATS_YEARS)

  if (loading) {
    return <Skeleton paragraph={{ rows: 3 }} active loading />
  }

  return (
    <>
      <Typography.Title level={4}>Historical Stats</Typography.Title>
      <Divider />
      <YearPicker
        years={data?.years || []}
        setYear={setYear}
        selectedYear={year}
      />

      {year && (
        <Tabs defaultActiveKey='1'>
          <TabPane tab='Partners' key='1'>
            <MerchantStats year={year} />
          </TabPane>
          <TabPane tab='Orders by Fulfilment' key='3'>
            <DeliveryPickupStats year={year} />
          </TabPane>
        </Tabs>
      )}
    </>
  )
}

const YearPicker = ({ years, setYear, selectedYear }) => {
  const onSelect = ({ key }) => {
    setYear(parseInt(key))
  }

  const menu = (
    <Menu onClick={onSelect}>
      {years.map(({ year }) => (
        <Menu.Item key={year}>{year}</Menu.Item>
      ))}
    </Menu>
  )

  return (
    <Dropdown overlay={menu}>
      <Button>
        {selectedYear || 'Select year'}
        <CaretDownOutlined />
      </Button>
    </Dropdown>
  )
}

export default StatsContainer
