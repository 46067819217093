import { useQuery } from '@apollo/client'
import { Skeleton } from 'antd'
import { QUERY_ALL_PLANS } from '../MerchantQueries'
import SubscriptionPlan from '../SubscriptionPlan'

interface SubscriptionPlanContainerProps {
  client: any
  merchant: any
}

const SubscriptionPlanContainer = ({
  client,
  merchant
}: SubscriptionPlanContainerProps) => {
  const { data, loading } = useQuery(QUERY_ALL_PLANS)

  if (loading) {
    return <Skeleton active />
  }

  if (data?.allPlans) {
    const initialPlan = data.allPlans.find(
      (x) => x.code === merchant.current_plan
    )
    const initialPlanOptions = data.allPlans.filter(
      (x) => x.code !== merchant.current_plan
    )

    return (
      <SubscriptionPlan
        plans={data.allPlans}
        initialPlan={initialPlan}
        initialPlanOptions={initialPlanOptions}
        merchant={merchant}
        client={client}
      />
    )
  }

  return null
}

export default SubscriptionPlanContainer
