import { Line, Area, Column, Gauge } from '@ant-design/plots'

const styles = {
  tooltip: {
    showMarkers: true
  },
  state: {
    // active: {
    //   style: {
    //     shadowBlur: 4,
    //     stroke: '#000',
    //     fill: 'green'
    //   }
    // }
  },
  interactions: [
    {
      type: 'marker-active'
    }
  ],
  areaStyle: () => {
    return {
      fill: 'l(270) 0:#ffffff 0.5:#7ec2f3 1:#1890ff'
    }
  },
  meta: {
    time: {
      sync: false // 开启之后 slider 无法重绘
    }
  },
  geometryOptions: [
    {
      geometry: 'column'
    },
    {
      geometry: 'line'
    }
  ]
}

interface GenericChartOptions {
  data: object[]
  field: string
  series?: string
  type?: string
  xField?: string
  height?: number
}

export const GenericChart = ({
  data,
  xField = 'day',
  field,
  series,
  type = 'line',
  height = 400
}: GenericChartOptions) => {
  // Add check for empty data
  if (!data || data.length === 0) {
    return <div>No data available</div>
  }

  const config = {
    data,
    xField: xField,
    yField: field,
    seriesField: series,
    height,
    ...styles
  }

  switch (type) {
    case 'line':
      return <Line {...config} />
    case 'column':
      return <Column isStack={false} slider={{}} isRange={true} {...config} />
    default:
      return (
        <Area
          isStack={false}
          slider={{
            start: 0,
            end: 1
          }}
          smooth={false}
          {...config}
        />
      )
  }
}

export const GaugeChart = ({ percent }: { percent: number }) => {
  // Add check for invalid percent value
  if (typeof percent !== 'number' || isNaN(percent)) {
    return <div>Invalid data</div> // Or any other placeholder
  }

  const config = {
    percent: percent,
    range: {
      color: 'l(0) 0:#B8E1FF 1:#30BF78'
    },
    startAngle: Math.PI,
    endAngle: 2 * Math.PI,
    indicator: null,
    statistic: {
      title: {
        offsetY: -36,
        style: {
          fontSize: '12px',
          color: '#4B535E'
        }
      },
      content: {
        style: {
          fontSize: '14px',
          color: '#4B535E'
        },
        formatter: () => `${(percent * 100).toFixed()}%`
      }
    }
  }

  return <Gauge {...config} height={80} />
}
