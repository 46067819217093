import { useState } from 'react'
import { ApolloClient, useMutation } from '@apollo/react-hooks'

import { Form, Select, notification, Modal, PageHeader, Tooltip } from 'antd'
import { UPDATE_MERCHANT_ZERO_ORDER_PLAN } from '../MerchantQueries'
import { Merchant, ZeroOrderPlan } from '../types'
import { QuestionCircleOutlined } from '@ant-design/icons'

const { Option } = Select

const Title = () => {
  return (
    <span>
      Free Order Commission Plan{' '}
      <Tooltip title='Free orders are purchases where products are discounted to £0 on checkout. This does not apply to rewards gained through loyalty.'>
        <QuestionCircleOutlined />
      </Tooltip>
    </span>
  )
}

interface FreeOrderCommissionPlanProps {
  plans: ZeroOrderPlan[]
  initialPlan: ZeroOrderPlan
  merchant: Merchant
  client: ApolloClient<any>
}

const FreeOrderCommissionPlan = ({
  plans,
  initialPlan,
  merchant,
  client
}: FreeOrderCommissionPlanProps) => {
  const [modalShown, setModalShown] = useState(false)
  const [selectedPlan, setSelectedPlan] = useState(initialPlan)
  const [isActivating, setIsActivating] = useState(false)

  const [updateFreeOrderCommissionPlan] = useMutation(
    UPDATE_MERCHANT_ZERO_ORDER_PLAN,
    { client: client }
  )

  return (
    <>
      <PageHeader title={<Title />} />
      <Modal
        title='Free Order Commission Plan'
        visible={modalShown}
        onOk={() => {
          setIsActivating(true)

          updateFreeOrderCommissionPlan({
            variables: {
              merchantId: merchant.id,
              zeroOrderRateId: selectedPlan.id
            }
          })
            .then(() => {
              notification['success']({
                message:
                  'Successfully updated partner free order commission plan'
              })
            })
            .catch(() => {
              notification['error']({
                message: 'Failed to update partner free order commission plan'
              })
            })
            .finally(() => {
              setModalShown(false)
              setIsActivating(false)
            })
        }}
        onCancel={() => {
          setModalShown(false)
          setSelectedPlan(initialPlan)
        }}
        confirmLoading={isActivating}
      >
        <>
          <span>
            You are about to set this plan to: <b>{selectedPlan.name}</b>
          </span>
          <br />
          <span>Do you wish to proceed?</span>
        </>
      </Modal>
      <Form labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}>
        <Form.Item
          label='Plan'
          extra='Please note that selecting a new plan from these options will change the free order commission plan.'
        >
          <Select
            value={selectedPlan.name}
            style={{ width: 250 }}
            onChange={(id) => {
              setSelectedPlan(plans.find((p) => p.id === id))
              setModalShown(true)
            }}
          >
            {plans.map((plan) => {
              return (
                <Option key={plan.id} value={plan.id}>
                  {plan.name}
                </Option>
              )
            })}
          </Select>
        </Form.Item>
      </Form>
    </>
  )
}

export default FreeOrderCommissionPlan
