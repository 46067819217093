import { useState } from 'react'
import { gql, InMemoryCache } from '@apollo/client'
import { ApolloClient, HttpLink } from '@apollo/client'
import { Form, Input, Button, Typography } from 'antd'
import { LockOutlined, UserOutlined } from '@ant-design/icons'
import './LoginForm.css'
import logo from '../../logo.png'

const AUTHENTICATE = gql`
  query authenticateUser($username: String!, $password: String!) {
    authenticate(email: $username, password: $password) {
      email
      apiKey
      role
    }
  }
`

// const FETCH_JWT = gql`
//   query fetchJwt($apiKey: String!) {
//     jwtToken(arg1: { apiKey: $apiKey }) {
//       token
//     }
//   }
// `

const NormalLoginForm = ({ endpoint }) => {
  const [form] = Form.useForm()
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)

  const authenticate = async (username: string, password: string) =>
    new ApolloClient({
      link: new HttpLink({
        uri: process.env.REACT_APP_AUTH_ENDPOINT
      }),
      cache: new InMemoryCache()
    }).query({
      query: AUTHENTICATE,
      variables: { username, password },
      errorPolicy: 'ignore',
      fetchPolicy: 'no-cache'
    })

  const fetchJwt = async (apiKey: string): Promise<string | undefined> => {
    return fetch(process.env.REACT_APP_JWT_ENDPOINT, {
      method: 'POST',
      headers: {
        authorization: `${apiKey}`,
        'Content-Type': 'application/json'
      }
    }).then(async (result) => {
      const jwt = await result.json()
      if (jwt?.token) {
        return jwt.token
      } else {
        return undefined
      }
    })
  }

  // new ApolloClient({
  //   link: new HttpLink({
  //     uri: process.env.REACT_APP_AUTH_ENDPOINT,
  //     headers: {
  //       authorization: `Bearer ${ apiKey } `
  //     }
  //   }),
  //   cache: new InMemoryCache()
  // }).query<{ jwtToken: { token: string } }>({
  //   query: FETCH_JWT,
  //   variables: { apiKey }
  // })

  const notifyError = () => setMessage('Unable to sign in. Please try again.')

  const handleSubmit = (values) => {
    setLoading(true)
    form
      .validateFields()
      .then(() => {
        const auth = authenticate(values.username, values.password)
        auth
          .then(async (result) => {
            if (
              result?.data?.authenticate.apiKey &&
              result?.data?.authenticate.role === 'superadmin'
            ) {
              await fetchJwt(result?.data?.authenticate.apiKey).then(
                (token) => {
                  if (token) {
                    localStorage.setItem('token', token)
                    localStorage.setItem(
                      'apiKey',
                      result?.data?.authenticate.apiKey
                    )
                    window.location.pathname = '/'
                  } else {
                    notifyError()
                  }
                }
              )
            } else {
              localStorage.removeItem('token')
              localStorage.removeItem('apiKey')
              notifyError()
            }
          })
          .catch((error) => {
            console.error(error) // Print what's happening.
            notifyError()
          })
          .finally(() => {
            setLoading(false)
          })
      })
      .catch((error) => {
        console.warn(error)
      })
  }

  const ShowError = () =>
    message ? <Typography.Text type='danger'>{message}</Typography.Text> : <></>

  return (
    <div className='login-container'>
      <Form form={form} className='login-form' onFinish={handleSubmit}>
        <div className='login-logo'>
          <img src={logo} alt='Slerp' />
        </div>
        <Form.Item name='username'>
          <Input
            prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            required={true}
            placeholder='Username'
          />
        </Form.Item>
        <Form.Item name='password'>
          <Input
            required={true}
            prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            type='password'
            placeholder='Password'
          />
        </Form.Item>
        <ShowError />
        <Form.Item>
          <Button
            type='primary'
            htmlType='submit'
            className='login-form-button'
            loading={loading}
          >
            {!loading ? 'Log in' : 'Logging in'}
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default NormalLoginForm
