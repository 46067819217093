import { useEffect, useState } from 'react'
import { Row, Col, PageHeader, Skeleton } from 'antd'
import 'moment-timezone'
import moment from 'moment-timezone'
import { GenericChart } from '../../Accounting/Charts'
import { DAILY_BINS } from '../../Accounting/ChartQueries'
import { GmvData } from '../../Accounting/Daily'
import { useQuery } from '@apollo/client'

const RANGE = 1 // in month

const OpsGMVCharts = (props) => {
  const currentDate = moment()
  const previousDate = moment().subtract(RANGE, 'month')
  const [startDate] = useState<moment.Moment>(previousDate)
  const [endDate] = useState<moment.Moment>(currentDate)
  const [dailyBins, setDailyBins] = useState([])
  const [dataSource, setDataSource] = useState<GmvData[]>()

  const { loading: isLoadingDailyBins } = useQuery(DAILY_BINS, {
    variables: {
      startDate,
      endDate
    },
    onCompleted: (data) => {
      setDailyBins(data?.daily_bins)
    },
    notifyOnNetworkStatusChange: true
  })

  const fetchGMVGTV = (data) => {
    return []
      .concat(
        data.map((d) => {
          return {
            day: d.day,
            value: d.gmv,
            category: 'GMV'
          }
        })
      )
      .concat(
        data.map((d) => {
          return {
            day: d.day,
            value: d.gtv,
            category: 'GTV'
          }
        })
      )
  }

  const transformBins = async () => {
    const data = dailyBins.map((kpi) => {
      const {
        gmv,
        gtv,
        transactions,
        discounts,
        partners,
        stores,
        day,
        application_fee
      } = kpi

      return {
        gmv: Math.floor(gmv),
        gtv: Math.floor(gtv),
        transactions,
        discounts,
        partners,
        stores,
        day: moment(day).format('DD-MM-YY'),
        application_fee,
        avg_basket_size: gmv / transactions
      }
    })

    setDataSource(data)
  }

  useEffect(() => {
    transformBins()
  }, [dailyBins])

  if (isLoadingDailyBins) {
    return <Skeleton active />
  }

  return (
    <Row gutter={24}>
      <Col xl={16} xs={24}>
        <PageHeader title='GMV' />
        {dataSource && (
          <GenericChart
            data={fetchGMVGTV(dataSource)}
            field='value'
            series='category'
            type='area'
          />
        )}
      </Col>
      <Col xl={8} xs={24}>
        <PageHeader title='Transactions' />
        {dataSource && (
          <GenericChart data={dataSource} field='transactions' type='column' />
        )}
      </Col>
    </Row>
  )
}

export default OpsGMVCharts
