import { Row, Col, Card, PageHeader } from 'antd'
import { AreaChart, BarChart } from 'react-chartkick'
import 'chart.js'
import { useQuery } from '@apollo/client'
import { QUERY_WEEKLY_ORDER_STATUSES, QUERY_HOURLY_ORDERS } from './HomeQueries'
import OrdersContainer from './OrdersContainer'
import MerchantsContainer from './MerchantsContainer'
import OpsStats from './Ops/OpsStats'
import OpsGMVCharts from './Ops/OpsGMVCharts'

const HourlyGMVChart = () => {
  const { data, loading } = useQuery(QUERY_HOURLY_ORDERS)

  if (loading) {
    return <Card title='Hourly orders' loading={loading} />
  }

  if (data?.view_hourly_orders) {
    const hourlyGMV = {}
    const hourlyCount = {}

    data.view_hourly_orders.forEach((item) => {
      hourlyCount[item.hour] = item.count
      hourlyGMV[item.hour] = item.gmv
      return [item.hour, item.gtv]
    })

    const chartData = [{ name: 'GMV', data: hourlyGMV }]

    return (
      <AreaChart
        data={chartData}
        colors={['#e1f887']}
        prefix={'£'}
        thousands=','
        dataset={{ borderWidth: 0, borderSkipped: false }}
      />
    )
  }

  return null
}

const WeeklyStatsChart = () => {
  const { data, loading } = useQuery(QUERY_WEEKLY_ORDER_STATUSES)

  if (loading) {
    return <Card title='7 day stats' loading={loading} />
  }

  if (data?.view_order_weekly_statuses) {
    const chartData = data.view_order_weekly_statuses.map((item) => [
      item.status,
      item.count
    ])
    return (
      <BarChart
        data={chartData}
        colors={[['#E1F887', '#F5AB9A', '#9AE0F5', '#F5EC9A', '#E5E5E5']]}
        dataset={{ borderWidth: 0, borderSkipped: false }}
      />
    )
  }

  return null
}

const HomeContainer = ({ client }) => {
  return (
    <>
      <OpsStats client={client} />

      <div style={{ marginBottom: '8px' }} />

      <OpsGMVCharts client={client} />

      <div style={{ marginBottom: '24px' }} />

      <Row>
        <Col xl={16} lg={24}>
          <PageHeader title='Hourly GMV' />
          <HourlyGMVChart />
        </Col>

        <Col xl={8} lg={24}>
          <PageHeader title='7 day stats' />
          <WeeklyStatsChart />
        </Col>
      </Row>

      <div style={{ marginBottom: '24px' }} />

      <Row gutter={24}>
        <Col xl={10} lg={24}>
          <MerchantsContainer client={client} />
        </Col>
        <Col xl={14} lg={24}>
          <OrdersContainer client={client} />
        </Col>
      </Row>
    </>
  )
}

export default HomeContainer
