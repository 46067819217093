import { useCallback, useEffect, useState } from 'react'
import {
  Row,
  Col,
  Statistic,
  Card,
  DatePicker,
  Divider,
  PageHeader,
  Layout,
  Tooltip
} from 'antd'
import {
  QuestionCircleFilled,
  RiseOutlined,
  FallOutlined
} from '@ant-design/icons'
import 'moment-timezone'
import { GenericChart, GaugeChart } from './Charts'
import moment from 'moment-timezone'
import { DAILY_BINS, GMV_TODAY } from './ChartQueries'
import { useQuery } from '@apollo/client'

const { RangePicker } = DatePicker

export const numberFormatter = new Intl.NumberFormat('en-GB', {
  style: 'currency',
  currency: 'GBP',
  minimumFractionDigits: 2
})

export type GmvData = {
  gmv: number
  gtv: number
  discounts: number
  transactions: number
  partners: number
  stores: number
  day: string
  application_fee: number
}

const defaultGmvData = {
  gmv: 0,
  gtv: 0,
  discounts: 0,
  transactions: 0,
  partners: 0,
  stores: 0,
  day: '2023-02-01',
  application_fee: 0
}

const Daily = (props) => {
  const { client } = props
  const currentDate = moment()
  const previousDate = moment().startOf('month')
  const [startDate, setStartDate] = useState<moment.Moment>(previousDate)
  const [endDate, setEndDate] = useState<moment.Moment>(currentDate)
  const [dailyBins, setDailyBins] = useState([])
  const [dataSource, setDataSource] = useState<GmvData[]>()

  const [gmvDataToday, setGmvDataToday] = useState<[GmvData, GmvData]>([
    defaultGmvData,
    defaultGmvData
  ])

  const { data: gmv_data_today } = useQuery(GMV_TODAY, {
    variables: {
      startDate: moment().add(-1, 'day').startOf('day'),
      endDate: moment().endOf('day')
    },
    pollInterval: 600000, // every 10 mins
    notifyOnNetworkStatusChange: true
  })

  const queryBins = async () => {
    // const adjustedStartDate = endDate.subtract(endDate.diff(startDate, 'day'), 'd')
    const dateDiff = startDate.diff(endDate, 'days')

    console.log(dateDiff)

    return await client.query({
      query: DAILY_BINS,
      variables: {
        startDate,
        endDate
      },
      notifyOnNetworkStatusChange: true
    })
  }

  const loadBins = async () => {
    await queryBins().then(async ({ data }) => {
      setDailyBins(data?.daily_bins)
    })
  }

  const handleRangePicker = (dates) => {
    setStartDate(dates[0])
    setEndDate(dates[1])
  }

  useEffect(() => {
    loadBins()
  }, [])

  useEffect(() => {
    loadBins()
  }, [startDate, endDate])

  const loadAll = async () => {
    const data = dailyBins.map((kpi) => {
      const {
        gmv,
        gtv,
        transactions,
        discounts,
        partners,
        stores,
        day,
        application_fee
      } = kpi

      return {
        gmv: Math.floor(gmv),
        gtv: Math.floor(gtv),
        transactions,
        discounts,
        partners,
        stores,
        day: moment(day).format('DD-MM-YY'),
        application_fee,
        avg_basket_size: gmv / transactions
      }
    })

    setDataSource(data)
  }

  useEffect(() => {
    loadAll()
  }, [dailyBins])

  const fetchGMVGTV = (data) => {
    return []
      .concat(
        data.map((d) => {
          return {
            day: d.day,
            value: d.gmv,
            category: 'GMV'
          }
        })
      )
      .concat(
        data.map((d) => {
          return {
            day: d.day,
            value: d.gtv,
            category: 'GTV'
          }
        })
      )
  }

  const rangePresets: Record<string, [moment.Moment, moment.Moment]> = {
    'Current Month': [moment().startOf('month'), moment()],
    'Last 7 Days': [moment().add(-7, 'd'), moment()],
    'Last 14 Days': [moment().add(-14, 'd'), moment()],
    'Last 60 Days': [moment().add(-90, 'd'), moment()],
    '6 Months': [moment().add(-6, 'months'), moment()],
    '12 Months': [moment().add(-365, 'd'), moment()]
  }

  const totalGMV = useCallback(() => {
    if (dataSource && dataSource.length > 0) {
      return dataSource.map((data) => data.gmv).reduce((s, a) => s + a, 0)
    } else {
      return 0
    }
  }, [dataSource])

  const totalTransactions = useCallback(() => {
    if (dataSource && dataSource.length > 0) {
      return dataSource
        .map((data) => data.transactions)
        .reduce((s, a) => s + a, 0)
    } else {
      return 0
    }
  }, [dataSource])

  // const totalCollectedFees = useCallback(() => {
  //   if (dataSource && dataSource.length > 0) {
  //     return dataSource.map(data => data.application_fee).reduce((s, a) => s + a, 0)
  //   } else {
  //     return 0
  //   }
  // }, [dataSource])

  useEffect(() => {
    if (gmv_data_today) {
      const { gmv_today } = gmv_data_today
      console.log(gmv_today)
      if (gmv_today && gmv_today[0] && gmv_today[1]) {
        setGmvDataToday([gmv_today[0], gmv_today[1]])
      } else {
        setGmvDataToday([defaultGmvData, defaultGmvData])
      }
    } else {
      setGmvDataToday([defaultGmvData, defaultGmvData])
    }
    console.log(gmvDataToday)
  }, [gmv_data_today, gmv_data_today?.gmv_today])

  const gmvToday = useCallback(() => {
    if (gmvDataToday[0] && gmvDataToday[1]) {
      return {
        today: gmvDataToday[0].gmv,
        yesterday: gmvDataToday[1].gmv,
        percent:
          gmvDataToday[0].gmv > 0
            ? gmvDataToday[0].gmv / gmvDataToday[1].gmv
            : 0,
        icon:
          gmvDataToday[0].gmv > gmvDataToday[1].gmv ? (
            <RiseOutlined style={{ color: 'green', fontSize: 14 }} />
          ) : (
            <FallOutlined style={{ color: 'red', fontSize: 14 }} />
          )
      }
    } else {
      return {
        today: 0,
        yesterday: 0,
        percent: 0,
        icon: <></>
      }
    }
  }, [gmvDataToday[0].gmv, gmvDataToday[1].gmv])

  const gmvTransactions = useCallback(() => {
    if (gmvDataToday[0] && gmvDataToday[1]) {
      return {
        today: gmvDataToday[0].transactions,
        yesterday: gmvDataToday[1].transactions,
        percent:
          gmvDataToday[0].gmv > 0
            ? gmvDataToday[0].transactions / gmvDataToday[1].transactions
            : 0,
        icon:
          gmvDataToday[0].transactions > gmvDataToday[1].transactions ? (
            <RiseOutlined style={{ color: 'green', fontSize: 14 }} />
          ) : (
            <FallOutlined style={{ color: 'red', fontSize: 14 }} />
          ),
        tooltip: (
          <Tooltip
            title={`From ${gmvDataToday[1].partners} partners and ${gmvDataToday[1].stores} locations`}
          >
            <QuestionCircleFilled />
          </Tooltip>
        )
      }
    } else {
      return {
        today: 0,
        yesterday: 0,
        percent: 0,
        icon: <></>,
        tooltip: <></>
      }
    }
  }, [gmvDataToday[0].gmv, gmvDataToday[1].gmv])

  const dateRangeAsString = useCallback(() => {
    return `${startDate.format('DD-MM-YYYY')} to ${endDate.format(
      'DD-MM-YYYY'
    )} `
  }, [startDate, endDate])

  return (
    <Layout>
      <Row align='middle'>
        <Col>
          <PageHeader title='Filter by date' />
        </Col>
        <Col>
          <RangePicker
            ranges={rangePresets}
            onChange={handleRangePicker}
            defaultValue={[startDate, endDate]}
            format={'DD-MM-YYYY'}
          />
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xl={6} lg={12} md={12} xs={24}>
          <Card
            style={{
              backgroundColor: 'white',
              marginBottom: '8px'
            }}
          >
            <Row gutter={16} align='bottom'>
              <Col xl={6}>
                <GaugeChart percent={gmvToday().percent} />
              </Col>
              <Col xl={16}>
                <Statistic
                  title={<>Orders Today {gmvToday().icon}</>}
                  value={numberFormatter.format(gmvToday().today)}
                  suffix={`Yesterday: ${numberFormatter.format(
                    gmvToday().yesterday
                  )} `}
                />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xl={6} lg={12} md={12} xs={24}>
          <Card
            style={{
              backgroundColor: 'white',
              marginBottom: '8px'
            }}
          >
            <Row gutter={16} align='bottom'>
              <Col xl={6}>
                <GaugeChart percent={gmvTransactions().percent} />
              </Col>
              <Col xl={16}>
                <Statistic
                  title={
                    <>
                      Transactions Today {gmvTransactions().icon}{' '}
                      {gmvTransactions().tooltip}
                    </>
                  }
                  value={gmvTransactions().today}
                  valueStyle={{ color: '#3f8600' }}
                  suffix={`Yesterday: ${gmvTransactions().yesterday} `}
                />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xl={6} lg={12} md={12} xs={24}>
          <Card
            style={{
              backgroundColor: 'white',
              marginBottom: '8px'
            }}
          >
            <Statistic
              title={
                <>
                  Orders this period{' '}
                  <Tooltip title={`For the period of: ${dateRangeAsString()} `}>
                    <QuestionCircleFilled />
                  </Tooltip>
                </>
              }
              value={numberFormatter.format(totalGMV())}
              suffix={`Similar Period: 0`}
            />
          </Card>
        </Col>
        <Col xl={6} lg={12} md={12} xs={24}>
          <Card
            style={{
              backgroundColor: 'white',
              marginBottom: '8px'
            }}
          >
            <Statistic
              title={
                <>
                  Transactions{' '}
                  <Tooltip title={`For the period of: ${dateRangeAsString()} `}>
                    <QuestionCircleFilled />
                  </Tooltip>
                </>
              }
              value={totalTransactions()}
              suffix={`Similar Period: 0`}
            />
          </Card>
        </Col>
        {/* <Col xl={8} lg={12} md={12} xs={24}>
          <Card
            style={{
              backgroundColor: 'white',
              marginBottom: '8px'
            }}
          >
            <Statistic
              title='Collected Fees'
              value={numberFormatter.format(
                totalCollectedFees())}
              suffix='Previous period: 1250'
            />
          </Card>
        </Col> */}
      </Row>

      <Row gutter={24}>
        <Col xl={16}>
          <PageHeader title='GMV' subTitle={dateRangeAsString()} />
          {dataSource && (
            <GenericChart
              data={fetchGMVGTV(dataSource)}
              field='value'
              series='category'
              type='area'
            />
          )}
        </Col>
        <Col xl={8}>
          <PageHeader title='Transactions' />
          {dataSource && (
            <GenericChart
              data={dataSource}
              field='transactions'
              type='column'
            />
          )}
        </Col>
      </Row>

      <Divider />

      <Row gutter={24}>
        <Col xl={12}>
          <PageHeader title='Partners Transacted' />
          {dataSource && (
            <GenericChart
              data={dataSource}
              field='partners'
              type='area'
              height={320}
            />
          )}
        </Col>
        <Col xl={12}>
          <PageHeader title='Locations Transacted' />
          {dataSource && (
            <GenericChart
              data={dataSource}
              field='stores'
              type='area'
              height={320}
            />
          )}
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xl={8}>
          <PageHeader title='Collected Fees' />
          {dataSource && (
            <GenericChart
              data={dataSource}
              field='application_fee'
              type='column'
              height={320}
            />
          )}
        </Col>
        <Col xl={8}>
          <PageHeader title='Discounts' />
          {dataSource && (
            <GenericChart
              data={dataSource}
              field='discounts'
              type='column'
              height={320}
            />
          )}
        </Col>
        <Col xl={8}>
          <PageHeader title='Average Basket Size' />
          {dataSource && (
            <GenericChart
              data={dataSource}
              field='avg_basket_size'
              type='column'
              height={320}
            />
          )}
        </Col>
      </Row>
    </Layout>
  )
}

export default Daily
