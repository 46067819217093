import { useQuery } from '@apollo/client'
import { Skeleton } from 'antd'
import { QUERY_MERCHANT_STORE_STATS } from './HomeQueries'
import MerchantsList from './MerchantsList'

interface MerchantsContainerProps {
  client: any
}

const MerchantsContainer = ({ client }: MerchantsContainerProps) => {
  const { data, loading } = useQuery(QUERY_MERCHANT_STORE_STATS, {
    fetchPolicy: 'network-only'
  })

  return (
    <div className='merchants'>
      {loading && <Skeleton active />}

      {data?.view_merchant_store_status && (
        <MerchantsList
          merchants={data.view_merchant_store_status}
          client={client}
        />
      )}
    </div>
  )
}

export default MerchantsContainer
