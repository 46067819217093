import { useSubscription } from '@apollo/client'
import { SUBSCRIPTION_LATEST_ORDERS } from '../Orders/OrderQueries'
import OrdersList from './OrdersList'
import { Skeleton } from 'antd'

const OrdersContainer = ({ client }) => {
  const { data, loading, error } = useSubscription(SUBSCRIPTION_LATEST_ORDERS)

  return (
    <>
      <div className='orders-filter'></div>
      <div className='orders'>
        {loading && <Skeleton paragraph={{ rows: 10 }} active loading />}

        {error && error.message}

        {data && data.message === null && error?.message}

        {data && data.orders && (
          <OrdersList orders={data.orders} client={client} />
        )}
      </div>
    </>
  )
}

export default OrdersContainer
