import { useQuery, ApolloClient } from '@apollo/client'
import { Skeleton } from 'antd'
import { QUERY_FREE_ORDER_COMMISSION_PLANS } from '../MerchantQueries'
import FreeOrderCommissionPlan from './FreeOrderCommissionPlan'
import { Merchant } from '../types'

interface FreeOrderCommissionContainerProps {
  client: ApolloClient<any>
  merchant: Merchant
}

const FreeOrderCommissionContainer = ({
  client,
  merchant
}: FreeOrderCommissionContainerProps) => {
  const { data, loading } = useQuery(QUERY_FREE_ORDER_COMMISSION_PLANS)

  if (loading) {
    return <Skeleton active />
  }

  if (data?.zero_order_rates) {
    const initialPlan = data.zero_order_rates.find(
      (x) => x.id === merchant.zero_order_rate_id
    )
    return (
      <FreeOrderCommissionPlan
        plans={data.zero_order_rates}
        initialPlan={initialPlan}
        merchant={merchant}
        client={client}
      />
    )
  }

  return null
}

export default FreeOrderCommissionContainer
