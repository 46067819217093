import { useQuery } from '@apollo/client'
import { Skeleton } from 'antd'
import { QUERY_MERCHANT } from './MerchantQueries'
import MerchantInfo from './MerchantInfo'

interface MerchantContainerProps {
  client: any
  slug: string
  tab?: string
}

const MerchantContainer = ({ client, slug, tab }: MerchantContainerProps) => {
  const { data, loading, refetch } = useQuery(QUERY_MERCHANT, {
    variables: { slug },
    fetchPolicy: 'no-cache'
  })

  if (loading) {
    return <Skeleton active />
  }

  if (data?.merchants) {
    return (
      <MerchantInfo
        merchant={data.merchants[0]}
        client={client}
        refetch={refetch}
        tab={tab}
      />
    )
  }

  return null
}

export default MerchantContainer
