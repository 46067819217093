import { gql } from '@apollo/client'

// Selecting two tables within a subscription is not possible, so we're using a query
const QUERY_LATEST_MERCHANT_ORDERS = gql`
  query merchantOrdersList($slug: String!) {
    orders(
      where: { merchant: { slug: { _eq: $slug } } }
      order_by: { inserted_at: desc }
      limit: 500
    ) {
      id
      merchant {
        name
      }
      transaction_id
      store {
        name
        comtrex_settings: settings(path: "comtrex")
        deliverect_link_id: settings(path: "deliverect_link_id")
      }
      delivery
      subtotal
      total
      fulfillment_type
      fulfillment_date
      fulfillment_time_range
      status
      inserted_at
      customer_details
      create_device_category
      create_platform
      metadata
    }
    merchants(where: { slug: { _eq: $slug } }) {
      name
      slug
    }
  }
`

export { QUERY_LATEST_MERCHANT_ORDERS }
