import { useState } from 'react'
import MerchantsContainer from './components/Merchants/MerchantsContainer'
import MerchantsDebuggerContainer from './components/Merchants/Debugger/MerchantsDebuggerContainer'
import MerchantContainer from './components/Merchants/MerchantContainer'
// import OrdersContainer from './components/Orders/OrdersContainer'
import HomeContainer from './components/Home/HomeContainer'
import IntegrationsContainer from './components/Integrations/IntegrationsContainer'
import TermsTracker from './components/Terms/TermsTracker'
import Stats from './components/Stats/StatsContainer'
import MonthlyKPI from './components/KPI/MonthlyKPI'
import CombinedMonthlyKPI from './components/KPI/CombinedMonthlyKPI'
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom'
import { Divider, Layout, Menu, Typography, Row, Col, PageHeader } from 'antd'
import {
  HomeOutlined,
  ShoppingOutlined,
  CalculatorOutlined,
  MessageOutlined,
  LogoutOutlined,
  TeamOutlined,
  EyeOutlined,
  BankOutlined
} from '@ant-design/icons'

import logo from './logo.png'
import { useMediaQuery } from 'react-responsive'
import Commission from './components/Commission'
import TransactionPlan from './components/TransactionPlan'
import CommissionForm from './components/Commission/CommissionForm'
import TransactionPlanForm from './components/TransactionPlan/TransactionPlanForm'
import AccountingSummaries from './components/Accounting/AccountingSummaries'
import CohortAnalysis from './components/Accounting/CohortAnalysis'
import { ApolloClient } from '@apollo/client'
import FastSearch from './components/Home/FastSearch'
import Summaries from './components/Home/Summaries'
import Daily from './components/Accounting/Daily'
import OpsDashboard from './components/Home/Ops/OpsDashboard'
import CohortAnalysisTable from './components/Accounting/CohortAnalysisTable'
import UnifiedTool from './components/Accounting/UnifiedTool'
import Clock from './components/Home/Ops/Clock'
import moment from 'moment-timezone'

const { Header, Sider, Content } = Layout
const DOMAIN_SUFFIX = process.env.REACT_APP_DOMAIN_SUFFIX

const signOut = () => {
  localStorage.removeItem('token')
  window.location.reload()
  return <></>
}

const Sidebar = (props) => {
  return (
    <Sider trigger={null} collapsible breakpoint='md' collapsedWidth='0'>
      <div className='logo'>
        <img className='brand' src={logo} alt='Slerp Admin' />
      </div>
      <Menu theme='dark' mode='vertical' defaultSelectedKeys={['1']}>
        <Menu.Item key='home'>
          <Link to='/'>
            <HomeOutlined />
            <span>Home</span>
          </Link>
        </Menu.Item>
        <Menu.Item key='orders'>
          <Link to='/orders'>
            <ShoppingOutlined />
            <span>Orders</span>
          </Link>
        </Menu.Item>

        <Menu.Item key='partners'>
          <Link to='/partners'>
            <TeamOutlined />
            <span>Partners</span>
          </Link>
        </Menu.Item>

        <Menu.SubMenu
          key='plan-group'
          title={
            <>
              <BankOutlined />
              <span>Plans</span>
            </>
          }
        >
          <Menu.Item key='commission-plans'>
            <Link to='/commission-plans'>
              <span>Commission Plans</span>
            </Link>
          </Menu.Item>
          <Menu.Item key='transaction-plans'>
            <Link to='/transaction-plans'>
              <span>Transaction Plans</span>
            </Link>
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.SubMenu
          key='accounting-group'
          title={
            <>
              <CalculatorOutlined />
              <span>Accounting</span>
            </>
          }
        >
          <Menu.Item key='acc-sum'>
            <Link to='/accounting'>
              <span>Summaries</span>
            </Link>
          </Menu.Item>
          <Menu.Item key='daily-gmv'>
            <Link to='/daily'>
              <span>Daily GMV</span>
            </Link>
          </Menu.Item>
          <Menu.Item key='acc-kpis'>
            <Link to='/kpi'>
              <span>Monthly KPIs</span>
            </Link>
          </Menu.Item>
          <Menu.Item key='acc-cohorts'>
            <Link to='/cohort-analysis'>
              <span>Cohort Analysis</span>
            </Link>
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.SubMenu
          key='tools-group'
          title={
            <>
              <MessageOutlined />
              <span>Tools</span>
            </>
          }
        >
          <Menu.Item key='integrators'>
            <Link to='/integrators'>
              <span>Integrators</span>
            </Link>
          </Menu.Item>
          <Menu.Item key='debugger'>
            <Link to='/debugger'>
              <span>Merchant Debugger</span>
            </Link>
          </Menu.Item>

          <Menu.Item key='pingdom'>
            <a
              href={`https://status.${DOMAIN_SUFFIX}`}
              target='_blank'
              rel='noreferrer'
            >
              <span>Server Status</span>
            </a>
          </Menu.Item>
          <Menu.Item key='terms-tracker'>
            <Link to='/terms-tracker'>
              <span>T&Cs Tracker</span>
            </Link>
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.Item key='sign-out'>
          <Link to='/sign-out' onClick={signOut}>
            <LogoutOutlined />
            <span>Sign Out</span>
          </Link>
        </Menu.Item>
      </Menu>
    </Sider>
  )
}

const bottomButton = {
  style: {
    display: 'flex',
    justifyContent: 'space-around',
    flexGrow: 1
  }
}

const linkStyle = {
  style: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexGrow: 1,
    color: 'rgba(255, 255, 255, 0.65)'
  }
}

const MobileMenu = () => (
  <div
    style={{
      position: 'fixed',
      height: '64px',
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 10000,
      background: '#001529',
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center'
    }}
  >
    <div {...bottomButton}>
      <Link to='/' {...linkStyle}>
        <HomeOutlined />
        <div>Home</div>
      </Link>
    </div>
    <div {...bottomButton}>
      <Link to='/orders' {...linkStyle}>
        <CalculatorOutlined />
        <span>Orders</span>
      </Link>
    </div>
    <div {...bottomButton}>
      <Link to='/orders/pending' {...linkStyle}>
        <EyeOutlined />
        <span>Pending</span>
      </Link>
    </div>
    <div {...bottomButton}>
      <Link to='/merchants' {...linkStyle}>
        <TeamOutlined />
        <span>Partners</span>
      </Link>
    </div>
  </div>
)

const AppContainer = ({ client }: { client: ApolloClient<object> }) => {
  const [collapsed] = useState(false)

  const merchant = ({ match }) => {
    return (
      <>
        <MerchantContainer
          client={client}
          slug={match.params.slug}
          tab={match.params.tab}
        />
      </>
    )
  }

  const merchants = ({ match }) => <MerchantsContainer client={client} />
  const merchantsDebugger = () => <MerchantsDebuggerContainer client={client} />
  const orders = ({ match }) => (
    <OpsDashboard client={client} search={match.params.transaction_id} />
  )
  const home = () => <HomeContainer client={client} />
  const stats = () => <Stats />
  const kpi = () => <MonthlyKPI />
  const kpiAll = () => <CombinedMonthlyKPI client={client} />
  const integrators = () => <IntegrationsContainer client={client} />
  const termsTracker = () => <TermsTracker client={client} />
  const commissionPlans = () => <Commission client={client} />
  const transactionPlans = () => <TransactionPlan client={client} />
  const newCommissionPlans = () => <CommissionForm client={client} />
  const newTransactionPlan = () => <TransactionPlanForm client={client} />
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)'
  })

  const SignOutLink = () => {
    signOut()
    return <></>
  }

  return (
    <Router>
      <Layout>
        {isTabletOrMobileDevice && (
          <Header
            style={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Link to='/'>
              <img src={logo} height='48px' alt='Slerp' />
            </Link>
            <Typography.Text style={{ color: 'white' }}>
              {new Date().toLocaleString()}
            </Typography.Text>
          </Header>
        )}

        {isTabletOrMobileDevice ? (
          <MobileMenu />
        ) : (
          <Sidebar collapsed={collapsed} />
        )}

        <Layout
          style={{
            overflowY: isTabletOrMobileDevice ? 'scroll' : 'unset'
          }}
        >
          <Content
            style={{
              margin: '8px 16px 48px',
              padding: isTabletOrMobileDevice ? 0 : '24px 24px 48px',
              background: '#fff',
              minHeight: '100vh',
              fontSize: '13px'
            }}
          >
            <Row align='top' gutter={0} justify='space-between'>
              <Col>
                <Row>
                  <Col>
                    <PageHeader title='Command Centre' style={{ padding: 0 }} />
                  </Col>
                  <Col>
                    <FastSearch client={client} />
                  </Col>
                </Row>
              </Col>
              <Col>
                <PageHeader
                  title={<Clock />}
                  subTitle={moment().format('Do MMMM YYYY')}
                  style={{ margin: 0, padding: 0 }}
                />
              </Col>
            </Row>

            <Divider />
            <Route path='/' exact component={home} />
            <Route
              path='/summaries'
              exact
              component={() => <Summaries client={client} />}
            />
            <Route
              path='/daily'
              exact
              component={() => <Daily client={client} />}
            />
            <Route
              path='/ops'
              exact
              component={() => <OpsDashboard client={client} />}
            />
            <Switch>
              <Route path='/orders/:transaction_id' exact component={orders} />
              <Route path='/orders' exact component={orders} />
            </Switch>

            <Switch>
              <Route
                path='/merchants/:slug/:tab/:transaction_id'
                exact
                component={merchant}
              />
              <Route path='/merchants/:slug/:tab' exact component={merchant} />
              <Route path='/merchants/:slug' exact component={merchant} />
            </Switch>
            <Route path='/merchants' exact component={merchants} />
            <Route path='/partners' exact component={merchants} />
            <Route path='/debugger' exact component={merchantsDebugger} />
            <Route
              path='/accounting'
              component={() => <AccountingSummaries />}
            />
            <Route path='/stats' component={stats} />
            <Route path='/kpi' component={kpi} />
            <Route path='/kpi-all' component={kpiAll} />
            <Route path='/commission-plans' exact component={commissionPlans} />
            <Route
              path='/transaction-plans'
              exact
              component={transactionPlans}
            />
            <Route
              path='/commission-plans/new'
              exact
              component={newCommissionPlans}
            />
            <Route
              path='/transaction-plans/new'
              exact
              component={newTransactionPlan}
            />

            <Route
              path='/cohort-analysis'
              component={() => <CohortAnalysis />}
            />
            <Route
              path='/cohort-analysis-table'
              component={() => <CohortAnalysisTable />}
            />

            <Route path='/integrators' component={integrators} />
            <Route path='/terms-tracker' component={termsTracker} />
            <Route path='/unified' component={() => <UnifiedTool />} />
            <Route path='/sign-out' component={SignOutLink} />
          </Content>
        </Layout>
      </Layout>
    </Router>
  )
}

export default AppContainer
