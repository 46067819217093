import { Row, Col, Skeleton, PageHeader } from 'antd'
import { useQuery } from '@apollo/client'
import { QUERY_DELIVERY_PICKUP_STATS } from './StatsQueries'
import { LineChart, AreaChart } from 'react-chartkick'
import { monthlySeries, cumulativeSeries } from './utils'

const DeliveryPickupStats = ({ year }) => {
  const { data, loading } = useQuery(QUERY_DELIVERY_PICKUP_STATS, {
    variables: { year }
  })

  return (
    <Row gutter={50}>
      {loading && <Skeleton paragraph={{ rows: 3 }} active loading />}

      {data &&
        (() => {
          let countsSeries = []
          let totalsSeries = []
          let countsCumulativeSeries = []

          if (data.delivery_stats) {
            const counts = monthlySeries(data.delivery_stats, year, {
              valueKey: 'count'
            })
            const totals = monthlySeries(data.delivery_stats, year, {
              valueKey: 'total'
            })
            const countsCumulative = cumulativeSeries(counts)

            countsSeries.push({
              name: 'Delivery',
              data: counts
            })
            countsCumulativeSeries.push({
              name: 'Delivery',
              data: countsCumulative
            })
            totalsSeries.push({
              name: 'Delivery',
              data: totals
            })
          }

          if (data.pickup_stats) {
            const counts = monthlySeries(data.pickup_stats, year, {
              valueKey: 'count'
            })
            const totals = monthlySeries(data.pickup_stats, year, {
              valueKey: 'total'
            })
            const countsCumulative = cumulativeSeries(counts)

            countsSeries.push({
              name: 'Pickup',
              data: counts
            })
            countsCumulativeSeries.push({
              name: 'Pickup',
              data: countsCumulative
            })
            totalsSeries.push({
              name: 'Pickup',
              data: totals
            })
          }

          return (
            <Col span={24}>
              <PageHeader title='Orders' />
              <LineChart
                data={countsSeries}
                colors={['#1890ff', '#18b040']}
                library={{ scales: { xAxes: [{ display: false }] } }}
              />
              <br />
              <PageHeader title='Cumulative' />
              <AreaChart
                data={countsCumulativeSeries}
                colors={['#1890ff', '#18b040']}
                stacked={true}
                library={{ scales: { xAxes: [{ display: false }] } }}
              />
              <PageHeader title='Charges' />
              <br />
              <LineChart
                data={totalsSeries}
                colors={['#1890ff', '#18b040']}
                prefix='£'
                thousands=','
              />
            </Col>
          )
        })()}
    </Row>
  )
}

export default DeliveryPickupStats
