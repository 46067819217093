import { useEffect, useState } from 'react'
import { ReloadOutlined, WarningFilled } from '@ant-design/icons'
import { Tag, Spin, Tooltip } from 'antd'
import { useMutation } from '@apollo/client'
import { MUTATION_RETRY_POS_ORDER } from './OrderQueries'

/** For status filtering  */
const OrderStatusesFilter = () => {
  return [
    {
      text: 'Completed',
      value: 'fulfilled'
    },
    {
      text: 'Accepted',
      value: 'accepted'
    },
    {
      text: 'Fulfilled',
      value: 'fulfilled'
    },
    {
      text: 'Cancelled',
      value: 'cancelled'
    },
    {
      text: 'Pending',
      value: 'pending'
    }
  ]
}

const OrderStatusTag = (status) => {
  switch (status) {
    case 'fulfilled':
      return <Tag color='darkgreen'>Completed</Tag>
    case 'accepted':
      return <Tag color='green'>Accepted</Tag>
    case 'refunded':
      return <Tag color='gray'>Refunded</Tag>
    case 'cancelled':
      return <Tag color='orange'>Cancelled</Tag>
    case 'pending':
      return <Tag color='red'>Pending</Tag>
    default:
      return <Tag color='lime'>{status}</Tag>
  }
}

const DeliveryStatusTag = (text, record) => {
  switch (text) {
    case 'searching':
      return (
        <>
          <Tag color='orange'>
            Searching <Spin size='small' />
          </Tag>
        </>
      )
    case 'accepted':
      return <Tag color='green'>Accepted</Tag>
    case 'picking':
      return <Tag color='blue'>Picking</Tag>
    case 'almost_picking':
      return <Tag color='blue'>Almost Picking</Tag>
    case 'waiting_at_pickup':
      return <Tag color='blue'>Waiting at Pickup</Tag>
    case 'waiting_at_dropoff':
      return <Tag color='lightgreen'>Waiting at Pickup</Tag>
    case 'success':
      return <Tag color='green'>Success</Tag>
    case 'cancelled':
      return <Tag color='red'>Cancelled</Tag>
    case 'failed':
      return <Tag color='red'>Failed</Tag>
    case 'almost_delivering':
      return <Tag color='blue'>Almost Delivering</Tag>
    case 'delivering':
      return <Tag color='blue'>Delivering</Tag>
    default:
      return <Tag color='gray'>{text}</Tag>
  }
}

const PushStatusTag = ({ record }) => {
  const [isReloadingOrder, setIsReloadingOrder] = useState<boolean>(false)
  const [reloadHandler, { data }] = useMutation(MUTATION_RETRY_POS_ORDER)

  useEffect(() => {
    if (data) {
      setIsReloadingOrder(true)
    }
    if (
      (record?.metadata?.deliverect_order_id ||
        record?.metadata?.comtrex?.Order?.GuestCheckNumber) &&
      isReloadingOrder
    ) {
      setIsReloadingOrder(false)
    }
  }, [data, record])

  if (record && record.metadata && record?.metadata?.deliverect_order_id) {
    return (
      <Tooltip
        title={`Deliverect Order ID: ${record.metadata?.deliverect_order_id}`}
      >
        <Tag color='green'>DELIVERECT: OK</Tag>
      </Tooltip>
    )
  } else if (
    record &&
    record.metadata &&
    record.metadata.comtrex?.Order?.GuestCheckNumber
  ) {
    return (
      <Tooltip
        title={`Guest Check Number: ${record.metadata?.comtrex?.Order?.GuestCheckNumber}`}
      >
        <Tag color='green'>COMTREX: OK</Tag>
      </Tooltip>
    )
  } else if (record.store?.deliverect_link_id) {
    return (
      <div {...reloadOrderStyle}>
        {isReloadingOrder && (
          <Spin {...{ style: { display: 'flex' } }} size='small' />
        )}
        {!isReloadingOrder && (
          <ReloadOutlined
            {...{ style: { width: '14px' } }}
            onClick={() =>
              reloadHandler({
                variables: { transactionId: record.transaction_id }
              })
            }
          />
        )}
        <Tag color='red'>
          DELIVERECT: <WarningFilled size={16} />
        </Tag>
      </div>
    )
  } else if (record.store?.comtrex_settings) {
    return (
      <div {...reloadOrderStyle}>
        {isReloadingOrder && (
          <Spin {...{ style: { display: 'flex' } }} size='small' />
        )}
        {!isReloadingOrder && (
          <ReloadOutlined
            {...{ style: { width: '14px' } }}
            onClick={() =>
              reloadHandler({
                variables: { transactionId: record.transaction_id }
              })
            }
          />
        )}
        <Tag color='red'>
          COMTREX: <WarningFilled size={16} />
        </Tag>
      </div>
    )
  } else {
    return <Tag color='gray'></Tag>
  }
}

const reloadOrderStyle = {
  style: {
    display: 'flex',
    gap: '5px',
    alignItems: 'center'
  }
}

export { OrderStatusesFilter, OrderStatusTag, DeliveryStatusTag, PushStatusTag }
