import { useState, SyntheticEvent } from 'react'
import { Table, Tag, Input } from 'antd'
import OrderDrawer from '../Orders/OrderDrawer'
import Moment from 'react-moment'
import {
  OrderStatusTag,
  DeliveryStatusTag,
  PushStatusTag
} from '../Orders/OrderStatuses'
import { capitalize } from './../Stats/utils'
import { useMediaQuery } from 'react-responsive'
import 'moment-timezone'
import { SearchOutlined } from '@ant-design/icons'
import OrderLink from '../Orders/OrderLink'

const OrdersList = ({ orders, client, merchant }) => {
  const [activeOrder, setActiveOrder] = useState(null)
  const [drawerVisible, setDrawerVisible] = useState(false)
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)'
  })

  const columns = [
    {
      title: 'Transaction Ref',
      dataIndex: 'transaction_id',
      key: 'transaction_id',
      render: (text, record) => (
        <OrderLink
          text={text}
          basePath={`/merchants/${merchant.slug}`}
          onClick={() => {
            showDrawer(record)
            return false
          }}
        />
      )
    },
    {
      title: 'Store',
      dataIndex: ['store', 'name'],
      key: 'store.name'
    },
    {
      title: 'Type',
      dataIndex: 'fulfillment_type',
      key: 'fulfillment_type'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: OrderStatusTag
    },
    {
      title: 'Delivery Status',
      dataIndex: ['delivery', 'delivery_status'],
      key: 'delivery.delivery_status',
      render: DeliveryStatusTag
    },
    {
      title: 'POS Status',
      dataIndex: ['order', 'metadata', 'deliverect_order_id'],
      key: 'order.metadata',
      render: (text, record) => <PushStatusTag record={record} /> // Ant and React seems to be acting up with too much useEffect() calls
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      render: (text, record) => {
        return <span>£{text}</span>
      }
    },
    {
      title: 'Expected At',
      dataIndex: 'fulfillment_date',
      key: 'fulfillment_date',
      render: (text, record) => {
        return (
          (record.fulfillment_date && (
            <>
              <Moment format='DD/MM/YY'>{text}</Moment>{' '}
              {record.fulfillment_time_range && (
                <> ({record.fulfillment_time_range})</>
              )}
            </>
          )) || <Tag color='red'>ASAP</Tag>
        )
      }
    },
    {
      title: 'Ordered At',
      dataIndex: 'inserted_at',
      key: 'inserted_at',
      render: (text) => {
        return <Moment>{text}</Moment>
      }
    },
    {
      title: 'Platform',
      dataIndex: 'platform',
      key: 'platform',
      render: (_, record) => {
        if (!record.create_device_category || !record.create_platform) {
          return 'Unknown'
        } else {
          return `${capitalize(record.create_platform)} - ${capitalize(
            record.create_device_category
          )}`
        }
      }
    }
  ]

  const showDrawer = (record) => {
    setActiveOrder(record)
    setDrawerVisible(true)
  }

  const onDrawerClose = () => {
    setActiveOrder(null)
    setDrawerVisible(false)
  }

  const onSearch = (e: SyntheticEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value
    if (value && value.length === 6) {
      showDrawer({
        transaction_id: String(value).toUpperCase()
      })
    }
  }

  return (
    <>
      <Input
        placeholder='Search order by transaction id. e.g. T3XJQM'
        onChange={onSearch}
        onSubmit={onSearch}
        suffix={<SearchOutlined />}
        style={{ marginBottom: '8px', maxWidth: '600px' }}
      />
      <Table
        dataSource={orders}
        columns={columns}
        pagination={{ defaultPageSize: 40 }}
        size={isTabletOrMobileDevice ? 'small' : 'middle'}
        tableLayout='auto'
      />
      <OrderDrawer
        order={activeOrder}
        client={client}
        visible={drawerVisible}
        onClose={onDrawerClose}
      />
    </>
  )
}

export default OrdersList
