import { Row, Col, Layout } from 'antd'
import 'moment-timezone'
import OrdersContainer from './OrdersTrackerContainer'
import OpsStats from './OpsStats'

const OpsDashboard = (props) => {
  const { client, search } = props
  return (
    <Layout>
      <OpsStats client={client} />

      <Row gutter={24}>
        <Col xl={24} lg={24}>
          <OrdersContainer client={client} search={search} />
        </Col>
      </Row>
    </Layout>
  )
}

export default OpsDashboard
